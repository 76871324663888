<template>
  <v-responsive
    class="mr-0 mr-md-6 hidden-xs-only transition-swing"
    :max-width="isFocused ? 300 : 250"
  >
    <v-autocomplete
      v-model="select"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      color="primary"
      dense

      flat
      hide-details
      prepend-inner-icon="mdi-magnify"
      rounded
      solo-inverted
      clearable
      hide-no-data
      hide-selected
      @change="modelChanged"
      item-text="sn"
      item-value="sn"
      placeholder="Search..."
      append-icon=""
      :filter="filterItems"
      return-object
    >
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item" />
        </template>
        <template v-else>
          <v-list-item-content
            @click="handleItemClick(data.item)"
          >
            <v-list-item-title v-html="data.item.sn" />
            <v-list-item-subtitle v-html="data.item.userName" />
            <v-list-item-subtitle v-html="data.item.Description" />
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </v-responsive>
</template>

<script>
  // https://codepen.io/anon/pen/WqXVWj?&editable=true&editors=101
  // copied from https://github.com/vuetifyjs/vuetify/blob/master/packages/docs/src/layouts/documentation/Search.vue
  // https://www.npmjs.com/package/vue-instantsearch
  // https://www.algolia.com/doc/guides/building-search-ui/getting-started/vue/
  import debounce from 'debounce'
  import { findAllEquipment } from '@/api/PouchDaoReport'
  import store from '@/store'

  export default {
    name: 'DocumentationSearch',
    data: () => ({
      descriptionLimit: 60,
      entries: [],
      isFocused: false,
      isLoading: false,
      select: null,
      search: null,
    }),
    computed: {
      fields () {
        if (!this.select) return []

        return Object.keys(this.select).map(key => {
          return {
            key,
            value: this.select[key] || 'n/a',
          }
        })
      },
      items () {
        // entries are list from sn tablle
        return this.entries.map(entry => {
          const line = entry.doc.acct + ' ' + entry.doc.deviceName
          const Description = line.length > this.descriptionLimit
            ? line.slice(0, this.descriptionLimit) + '...'
            : line
          return Object.assign({}, entry.doc, { Description })
        })
      },
    },

    watch: {
      search (val) {
        // Items have already been loaded

        console.log('searching value ' + val)

        if (this.items.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        // Debounce the input and wait for a pause of at
        // least 200 milliseconds. This can be changed to
        // suit your needs.
        debounce(
          // find SN
          this.searchByServiceNumber
          , 500)(val, this)
      },
    },
    methods: {
      modelChanged () {
        console.log(' modelChanged selected is ' + this.select.sn)
        // this.gotoServiceNumberPage(this.select.sn)
        this.$nextTick(() => {
          this.select = null // clear selected number in search box
        })
      },
      searchByServiceNumber (searchString) {
        // this.$gtag.event('searchByServiceNumber', { page: 'search' })
        this.$gtag.event('search', {
          search_term: searchString
        })
        findAllEquipment()
          .then(res => {
            console.log('findAllEquipment completed')
            if (res) {
              this.count = res.length
              this.entries = res
            }
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
      },
      handleItemClick (item) {
        console.log('handleItemClick on ' + item.sn)
        // this.$router.push({ path: '/pages/serviceNumberPage/' + item.sn})
        this.gotoServiceNumberPage(item.sn)
      },
      gotoServiceNumberPage (sn) {
        console.log('gotoServiceNumberPage current route name ' + this.$route.name, this.$route.path)
        if (this.$route.name !== 'ServiceNumberPage') {
          const path = `/pages/serviceNumberPage/${sn}`
          console.log('gotoServiceNumberPage replace ' + path)
          this.$router.replace(path)
          // this.$router.replace({ name: 'ServiceNumberPage', params: { snData: sn } })
        } else { // we are already on the service number page... dispatch event to vuex
          console.log('gotoServiceNumberPage dispatch serviceNumber/refreshCurrentSn ' + sn)
          store.dispatch('serviceNumber/refreshCurrentSn', sn)
        }
      },
      filterItems(item, queryText, itemText) {
        return (
          (item.sn && item.sn.indexOf(queryText) > -1) ||
          (item.sim && item.sim.indexOf(queryText) > -1) ||
          (item.imei && item.imei.indexOf(queryText) > -1) ||
          (item.userName && item.userName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1)
        );
      }

    },

  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/settings/_elevations.scss'
  @import '~vuetify/src/styles/tools/_elevation.sass'

  .algolia-autocomplete
    flex: 1 1 auto
    position: fixed !important

  #search
    width: 100%

  #app
    .algolia-docsearch-suggestion--title
      margin-bottom: 0

    .algolia-autocomplete
      a
        text-decoration: none !important

      > span
        left: -36px !important
        top: 0 !important
        @include elevation(8)

        &:before,
        &:after
          display: none

        .ds-dataset-1
          border: none !important
</style>
